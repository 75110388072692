var moment = require('moment');

$(document).ready(function () {

  // Expected date of birth message
  const currentDate = moment().startOf('day');
  const firstEligibleDate = moment("2020-01-01").startOf('day');

  const validateDOB = function () {
    const userInputDobString = $('#applicant_baby_expected_dob').val();
    const userInputDob = moment(userInputDobString, 'MM-DD-YYYY').startOf('day');

    const $ineligbleDobMessage = $('#ineligible-dob-message');
    const $enrollDirectlyMessage = $('#enroll-directly-message');
    const $submitButton = $('.submit-button');

    if (userInputDob.diff(firstEligibleDate, 'days') < 0) {
      $ineligbleDobMessage.removeClass('hidden');
      $enrollDirectlyMessage.addClass('hidden');
      $submitButton.addClass('disabled').prop('disabled', true);
    } else if (userInputDob.diff(currentDate, 'days') <= 0) {
      $ineligbleDobMessage.addClass('hidden');
      $enrollDirectlyMessage.removeClass('hidden');
      $submitButton.addClass('disabled').prop('disabled', true);
    } else if (userInputDob.diff(currentDate, 'days') > 0) {
      $ineligbleDobMessage.addClass('hidden');
      $enrollDirectlyMessage.addClass('hidden');
      $submitButton.removeClass('disabled').prop('disabled', false);
    }
  }

  validateDOB();
  // $('#applicant_baby_expected_dob').change("invalid",validateDOB);

  // Other how did you hear about field

  setUpDropdownWithCustomInput("#applicant_how_did_you_hear_about", ".applicant_how_did_you_hear_about_other", "Other");

  // Custom language field

  setUpDropdownWithCustomInput("#applicant_language_id", ".applicant_language_custom", "Custom (fill in)");

  function setUpDropdownWithCustomInput(dropdownFieldName, customFieldName, customOptionName) {
    const dropdownField = $(dropdownFieldName)
    const dropdownFieldCurrentlySelected = `${dropdownFieldName} option:selected`
    const customField = $(customFieldName)

    if ($(dropdownFieldCurrentlySelected).text() == customOptionName) {
      customField.show()
    } else {
      customField.hide(0)
    }

    $(dropdownField).change(function() {
      if ($(dropdownFieldCurrentlySelected).text() == customOptionName) {
        customField.show(200)
      } else {
        customField.hide(200)
      }
    });
  }

});
